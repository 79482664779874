<template>
    <div class="container " v-if="currentParcel && sameGroupLists">
        <div class="row ">
            <div class="col-12 col-md-6 col-lg-6">
                <div class="card">
                    <div class="card-header justify-content-center align-items-center">
                        <span class="fw-bolder fs-5">Group: {{this.currentParcel[0].parcelGroup}}</span>
                        <span class="badge rounded-pill"
                              :class="this.currentParcel[0].parcelCity===this.currentParcel[0].parcelUpdatedCity ? 'bg-success':'bg-warning'"
                        >
                            {{this.currentParcel[0].parcelCity===this.currentParcel[0].parcelUpdatedCity?"Arrived":"On going"}}
                        </span>
                    </div>
                    <div class="card-body">
                        <p>Order Number: {{this.currentParcel[0].parcelOrderNumber}}</p>
                        <p>MS Code: {{this.currentParcel[0].parcelMSCode}}</p>
                        <p>From: {{this.currentParcel[0].parcelBranch}}</p>
                        <p>Current: <span>{{this.currentParcel[0].parcelUpdatedCity?this.currentParcel[0].parcelUpdatedCity:this.currentParcel[0].parcelBranch }}</span></p>
                        <p>To: {{this.currentParcel[0].parcelCity}}</p>
                        <p>Parcel Owner: {{this.currentParcel[0].parcelName}}</p>
                        <p>Phone: {{this.currentParcel[0].parcelPhone}}</p>
                        <p>Date: {{this.currentParcel[0].parcelDate.toDate().toLocaleTimeString('en-US',{ weekday:"short", year:"numeric", month:"short"})}}</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6">
                <div class="card">
                    <div class="card-header bg-warning fs-5 fw-bolder"><i class="fa fa-edit"></i> Update All Parcel Destination</div>
                    <div class="card-body">
                        <div>
                            <p>From: </p>
                            <p class="fw-bolder" v-if="this.currentParcel[0].parcelUpdatedCity">{{this.currentParcel[0].parcelUpdatedCity}}</p>
                            <p class="fw-bolder" v-else> {{this.currentParcel[0].parcelBranch}}</p>
                        </div>

                        <div class="d-lg-flex d-md-flex justify-content-between align-items-center">
                            <div>
                                <label for="to-city">To:</label>
                                <select class="form-select mb-3" id="to-city" v-model="nextCity"  aria-label="default select example">
                                    <option value="" selected disabled >Select City</option>
                                    <option v-for="(city , index) in cities" v-bind:key="index" :class="city === branch?'d-none':''" >
                                        {{city}}
                                    </option>
                                </select>
                            </div>
                            <button class="btn btn-warning text-white fw-bolder" @click="updateDestination">Update All</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h1 class="bg-warning p-2 ">Same Parcel</h1>

            </div>
            <ParcelCards :parcel="parcel" v-for="(parcel, index) in sameGroupLists" :key="index"/>
        </div>
    </div>
</template>

<script>
    import ParcelCards from "../../components/ParcelCards";
    import db, {timestamp} from "../../firebaseConfig";
    export default {
        name:"ParcelGroupDetails",
        components: {ParcelCards},
        data(){
            return{
                currentParcel:null,
                nextCity:""
            }
        },
        async mounted(){
            this.currentParcel = await this.$store.state.todayParcelLists.filter((parcel)=>{
                return parcel.parcelID === this.$route.params.parcelID;
            });
            /*this.sameGroupLists = await this.$store.state.todayParcelLists.filter((parcel)=>{
                return parcel.parcelID !== this.$route.params.parcelID && parcel.parcelGroup === this.currentParcel[0].parcelGroup
            })*/
        },

        computed:{
            cities(){
                return this.$store.state.cities;
            },
            sameGroupLists(){
                this.$store.commit("filterSameGroup", this.currentParcel[0]);
                return this.$store.state.sameGroupLists;
            },
            branch(){
                return this.$store.state.userBranch;
            }

        },

        methods:{
            updateDestination(){
                //Update the ChangeCity in parcelLists and also set the changes record in record collection.
                const mainData = db.collection("parcelLists").doc(this.currentParcel[0].parcelID);
                mainData.update({
                    changedCity:this.nextCity
                }).then(()=>{
                    //set the change record to the current parcel
                    const updateData = db.collection("parcelLists").doc(this.currentParcel[0].parcelID).collection('record').doc();
                    updateData.set({
                        date:timestamp,
                        process:"update",
                        id:updateData.id,
                        to:this.nextCity,
                        branch:this.branch,
                    }).then(()=>{
                        //also set the changes record to all parcel of same group
                        this.sameGroupLists.forEach(parcel=>{
                            const updateChangedCity = db.collection("parcelLists").doc(parcel.parcelID);
                            updateChangedCity.update({
                                changedCity:this.nextCity
                            })
                                .then(()=>{
                                const updateData = db.collection("parcelLists").doc(parcel.parcelID).collection('record').doc();
                                updateData.set({
                                    date:timestamp,
                                    process:"update",
                                    id:updateData.id,
                                    to:this.nextCity,
                                    branch:this.branch,
                                }).then(()=>{
                                    alert("Update OK!");
                                    this.$router.push({name:'CreateParcel'});
                                    window.location.reload();
                                }).catch(err=>{
                                    alert(err.message);
                                });
                            }).catch(err=>{
                                console.log(err.message);
                            });
                        });
                    }).catch(err=>{
                        alert(err.message);
                    });
                }).catch(err=>{alert(err.message)});
            }
        }
    }
</script>
<style scoped>
    button, select, .card,  .card-header, h1{
        border-radius: 20px !important;
    }

</style>