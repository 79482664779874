<template>
  <div>
    <div>
      <qrcode-stream @decode="onDecode" @init="onInit" />
    </div>
    <div>
      <h5 v-if="(this.invalidID === true)" class="text-center mt-2" style="color: red;">Your Parcel QR Code is not Valid!</h5> 
    </div>
  </div>
</template>

<script>
import firebase from "@/firebaseConfig";
const db = firebase.firestore();

export default {
  data() {
    return {
      parcelID: '',
      errmsg: false,
      invalidID: false
    }
  },
  methods: {
    onDecode (result) {
      this.parcelID = result
      if (this.parcelID !== '') {
        db.collection("parcel").doc(this.parcelID)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.$router.push({
              name: 'parcelDetails',
              params: { parcelid: this.parcelID }
            });
            this.parcelID = '';
            this.invalidID = false;
          }
          else {
            this.invalidID = true;
          }
        }).catch((error) => {
            console.log(error)
        })
        this.errmsg = false;
      }
      else {
        this.errmsg = true;
        this.invalidID = false;
      }
    },
    async onInit (promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = "ERROR: you need to grant camera access permisson"
        } else if (error.name === 'NotFoundError') {
          this.error = "ERROR: no camera on this device"
        } else if (error.name === 'NotSupportedError') {
          this.error = "ERROR: secure context required (HTTPS, localhost)"
        } else if (error.name === 'NotReadableError') {
          this.error = "ERROR: is the camera already in use?"
        } else if (error.name === 'OverconstrainedError') {
          this.error = "ERROR: installed cameras are not suitable"
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = "ERROR: Stream API is not supported in this browser"
        }
      }
    }
  }
}
</script>

<style scoped>
.notfound {
  text-align: center;
  padding-top: 300px;
}

@media screen and (max-width: 600px) {
  .notfound {
    text-align: center;
    padding-top: 150px;
  }  
}
</style>
