<template>
    <div class="col-12 col-md-4 col-lg-3">
        <div class="card shadow-sm rounded mb-3">
            <div class="card-header d-flex justify-content-between align-items-center">
                <span class="fw-bolder">{{parcel.group}}</span>
            </div>
                <div class="card-body table-responsive">
                    <table class="table-striped">
                        <thead></thead>
                        <tbody>
                        <tr>
                            <td>Status</td>
                            <td>
                             <span
                                     class="badge rounded-pill"
                                     :class="parcel.to===parcel.changedCity ? 'bg-success':'bg-warning'"
                             >
                                 {{parcel.to===parcel.changedCity?"Arrived":"On going"}}
                             </span>
                            </td>

                        </tr>
                        <tr>
                            <td>Order Number</td>
                            <td>
                                : {{parcel.orderNumber}}
                            </td>
                        </tr>
                        <tr>
                            <td>MS Code</td>
                            <td>: {{parcel.msCode}}</td>
                        </tr>
                        <tr>
                            <td>From</td>
                            <td>: {{parcel.branch}}</td>
                        </tr>
                        <tr>
                            <td>Current City</td>
                            <td>: {{parcel.changedCity?parcel.changedCity:parcel.branch}}</td>
                        </tr>
                        <tr>
                            <td>To</td>
                            <td>: {{parcel.to}}</td>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td>: {{parcel.name}}</td>
                        </tr>
                        <tr>
                            <td>Phone</td>
                            <td>: {{parcel.phone}}</td>
                        </tr>
                        <tr>
                            <td>Date</td>
                            <td>
                                : {{parcel.date.toDate().toLocaleTimeString('en-US',{ weekday:"short", year:"numeric", month:"short"})}}
                            </td>
                        </tr>
                        </tbody>
                    </table>

                </div>
        </div>
    </div>

    <!--<div class="col-12 col-md-4 col-lg-3">
        <div class="card shadow-sm rounded mb-3">
            <div class="card-header">
                <span class="fw-bolder">{{parcel.parcelGroup}}</span>
            </div>
            <div class="card-body">
                <p>Status:
                    <span
                            class="badge rounded-pill"
                            :class="this.parcel.parcelCity===this.parcel.parcelUpdatedCity ? 'bg-success':'bg-warning'"
                    >
                        {{this.parcel.parcelCity===this.parcel.parcelUpdatedCity?"Arrived":"On going"}}
                    </span>
                </p>
                <p>Order Number: {{parcel.parcelOrderNumber}}</p>
                <p>MS Code: {{parcel.parcelMSCode}}</p>
                <p>From: {{parcel.parcelBranch}}</p>
                <p>Current City: {{parcel.parcelUpdatedCity?parcel.parcelUpdatedCity:parcel.parcelBranch}}</p>
                <p>To: {{parcel.parcelCity}}</p>
                <p>Name: {{parcel.parcelName}}</p>
                <p>Phone: {{parcel.parcelPhone}}</p>
                <p>Date: {{parcel.parcelDate.toDate().toLocaleTimeString('en-US',{ weekday:"short", year:"numeric", month:"short"})}}</p>
            </div>
        </div>
    </div>-->
</template>

<script>
    export default {
        name:"ParcelCards",
        props:["parcel", "parcelID"],
    }
</script>

<style scoped>
    .card, .card-header{
        border-radius: 20px !important;
    }
    td:first-child{
        text-align: start;
    }
    td:last-child{
        text-align: start;
        white-space: nowrap;
    }
    tr{
        margin: 5px;
        border-bottom: 1px solid #ffecb1;
        border-radius: 20px;
    }
    td{
        padding: 5px;
        white-space: nowrap;
    }
</style>