<template>
    <div v-if="this.currentParcel && this.$store.state.user">
        <Loading v-if="loadingActive"/>
        <InfoModal v-if="modalActive" :modal-message="modalMessage" v-on:close-modal="closeModal"/>
        <div class="container mb-5" v-if="currentParcel && sameGroupLists">
          <!--Current Parcel Detail-->
          <div class="row">
            <!--Update Form for City change or Group ID change-->
            <div class="col-12 justify-content-between div-card align-items-center" style="margin-top: 100px;">
              <div class="form-check mb-3 align-items-center fs-5 fw-bold">
                <span>Current Parcel Details </span>
              </div>
            </div>

            <div class="div-card bg-white border border-2 m-1">
              <div class="row justify-content-around">
                <!-- Current Parcel Details Box-->

                <div class="col-12 col-md-6 col-lg-6 order-1 order-lg-0 order-md-0">
                  <div class="div-card table-responsive mt-2">
                    <div class="d-flex justify-content-end" v-if="retrieveCheck">
                      <button class="btn btn-dark" @click="retrieved">Retrieved?</button>
                    </div>

                    <table class="table-striped mx-auto">
                      <thead></thead>
                      <tbody>

                      <tr>
                        <td>Status</td>
                        <td>
                            <span class="badge rounded-pill" :class="this.currentParcel.to===this.currentParcel.changedCity ? 'bg-success':'bg-warning'">
                                {{this.currentParcel.to===this.currentParcel.changedCity?"Arrived":"On going"}}
                            </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Order Number</td>
                        <td>
                          : {{this.currentParcel.orderNumber}}
                        </td>
                      </tr>
                      <tr>
                        <td>MS Code</td>
                        <td>: {{this.currentParcel.msCode}}</td>
                      </tr>
                      <tr>
                        <td>Group</td>
                        <td>: {{this.currentParcel.group}}</td>
                      </tr>
                      <tr>
                        <td>From</td>
                        <td>: {{this.currentParcel.branch}}</td>
                      </tr>
                      <tr>
                        <td>Current City</td>
                        <td>: {{this.currentParcel.changedCity?this.currentParcel.changedCity:this.currentParcel.branch}}</td>
                      </tr>
                      <tr>
                        <td>To</td>
                        <td>: {{this.currentParcel.to}}</td>
                      </tr>
                      <tr>
                        <td>Name</td>
                        <td>: {{this.currentParcel.name}}</td>
                      </tr>
                      <tr>
                        <td>Phone</td>
                        <td>: {{this.currentParcel.phone}}</td>
                      </tr>
                      <tr>
                        <td>Date</td>
                        <td>
<!--                          : {{this.currentParcel.date?this.currentParcel.date.toDate().toLocaleTimeString([],{ day:"numeric",month:"numeric", year:"numeric"}):''}}-->

                          : {{this.currentParcel.filterDate}}
                        </td>
                      </tr>
                      </tbody>
                    </table>

                  </div>
                </div>

                <!-- Current Parcel Group ID update option box for Myawaddy Branch-->
                <div class="col-12 col-md-6 col-lg-6 order-0 order-lg-1 order-md-1 mt-2" :class="checked?'d-none':''" v-if="user.branch==='Myawaddy'">
                  <div class="div-card p-2 text-center">
                    <span class="fs-5 fw-bold 2">Update Group ID for current item</span>

                    <div class="d-flex justify-content-center align-items-center ">
                      <div class="form-floating">
                        <input type="text" id="group_id" v-model="groupId" class="form-control" required placeholder="Enter Group ID">
                        <label for="group_id">Enter Group ID</label>
                      </div>
                      <button class="btn btn-dark text-white" @click="updateCurrentParcelGroupID">Update</button>
                    </div>
                  </div>

                  <!--  Record List-->
                  <div class="card mt-2 div-card" style="height: 250px !important;">
                    <p class="fs-5 fw-bold mb-3 text-center">Update List</p>
                    <div class="card-body text-center align-items-center overflow-scroll" v-if="recordLists.length>0">
                      <div v-for="record in recordLists" class="row mb-2 shadow-sm text-black-50 rounded-3 mx-2" :key="record.id" :class="record.process==='retrieved'?'bg-success':'bg-white'">

                        <div class="col-12 text-start"><i class="fa fa-store mx-1"></i><span >{{ record.branch }}-({{record.process}})</span></div>
                        <div class="col-12 text-start"><i class="fa fa-user-circle mx-1"></i><span>{{ record.user }}</span></div>
                        <div class="col-12 text-start "><i class="fa fa-clock mx-1"></i><span>{{record.date?record.date.toDate().toLocaleTimeString([],{ day:"numeric",month:"numeric", year:"numeric"}):'' }}</span></div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Current Parcel city update option for Other branch -->
                <div class="col-12 col-md-6 col-lg-6 order-0 order-lg-1 order-md-1  mt-2"  :class="checked?'d-none':''"  v-else>
                  <div class="div-card p-2">
                    <b-row class="text-center align-items-center ">
                      <span class="fs-5 fw-bold">Update Current City</span>
                      <button class="btn btn-dark w-50 mt-3 mx-auto text-white" @click="updateCurrentParcelCity">Received from {{user.branch}}</button>
                    </b-row>
                  </div>
                  <!--  Record List-->
                  <div class="card mt-2 div-card " style="height: 250px !important;">
                    <div class="card-body text-center align-items-center overflow-scroll">
                      <span class="fs-5 fw-bold mb-3">Update List</span>
                      <div v-for="record in recordLists" class="row mb-1 rounded-3 mx-2" :key="record.id" :class="record.process==='retrieved'?'bg-success text-white':'bg-white'">
                        <div class="col-12 text-start"><i class="fa fa-store mx-1"></i><span>{{ record.branch }}-({{record.process}})</span></div>
                        <div class="col-12 text-start"><i class="fa fa-user-circle mx-1"></i><span>{{ record.user }}</span></div>
                        <div class="col-12 text-start "><i class="fa fa-clock mx-1"></i><span>{{record.date?record.date.toDate().toLocaleTimeString([],{ day:"numeric",month:"numeric", year:"numeric"}):'' }}</span></div>
                      </div>
                    </div>

                  </div>


                </div>
              </div>

            </div>


            <b-col cols="12" class="mb-3">

              <!--Related Items Table Header with multiple select Update functions Box -->

              <div class="bg-light my-2 custom-bg d-lg-flex justify-content-between align-items-center">
                <div class="fs-5 fw-bold mx-2 my-2">
                  <input class="form-check-input" v-model="checked" @change="checkAll($event)" type="checkbox" id="flexSwitchCheckDefault">
                  <label class="form-check-label mx-2" for="flexSwitchCheckDefault" v-if="user.branch==='Myawaddy'">Update Group ID for All </label>

                  <label class="form-check-label mx-2 " for="flexSwitchCheckDefault" v-else>Update All {{this.currentParcel.group?'from - '+this.currentParcel.group:''}}</label>

                </div>

                <!--Multiple Select Group ID update Box For Myawaddy Branch-->
                <div class="d-flex justify-content-between align-items-center"  v-if="user.branch ==='Myawaddy'">
                  <div class="d-flex justify-content-around align-items-center my-1 ">
                    <div class="form-floating p-0">
                      <input type="text" id="group_id_multi" v-model="groupId1" class="form-control m-0" required placeholder="Enter Group ID">
                      <label for="group_id_multi">Enter Group ID</label>
                    </div>
                    <button class="btn btn-dark mx-1 py-2" v-if="checked" @click="updateGroupIdAll" >Update</button>
                    <button class="btn btn-dark mx-1 py-2" v-else @click="updateGroupIDByManualSelect" >Update</button>

                  </div>
                </div>

                <!-- Multiple Select City update Box For Other Branches-->
                <div class="d-flex justify-content-between align-items-center mb-2"  v-else>
                  <div class="d-flex align-items-center ">
                    <button class="btn btn-dark mx-1" v-if="checked" @click="updateCityForAll" >Received from {{user.branch}}</button>
                    <button class="btn btn-dark mx-1" v-else @click="updateCityByManualSelect" >Received from {{user.branch}}</button>

                  </div>
                </div>
              </div>

            </b-col>
            <!-- Table View-->

            <b-col cols="12" class="table-responsive mb-5">
              <table class="table table-hover table-striped border">
                <thead>
                <tr>

                  <th class="text-nowrap">Select</th>
                  <th>#</th>
                  <th class="text-nowrap">Customer Name</th>
                  <th class="text-nowrap">MS Code</th>
                  <th class="text-nowrap">Order Number</th>
                  <th class="text-nowrap">Phone</th>
                  <th class="text-nowrap">Group</th>
                  <th class="text-nowrap">Current City</th>
                  <th class="text-nowrap">To</th>
                  <th class="text-nowrap">Status</th>
                  <th class="text-nowrap">Date</th>

                  <!--                                <th class="text-nowrap">Date</th>-->
                </tr>
                </thead>

                <!-- For Same Date Lists (Myawaddy)-->
                <tbody v-if="relatedParcelLists.length>0">

                <tr v-for="(parcel, index) in relatedParcelLists" :key="parcel.data" class="align-middle text-start"  @click="showParcel(parcel)">
                  <td class="text-nowrap">
                    <div class="form-check">
                      <input class="form-check-input" :checked="checked" :value="parcel.data" type="checkbox"  @change="check($event)">
                    </div>
                  </td>
                  <td>{{index+1}}</td>
                  <td>{{parcel.name}}</td>
                  <td>{{parcel.msCode}}</td>
                  <td>{{parcel.orderNumber}}</td>
                  <td>{{parcel.phone}}</td>
                  <td class="text-nowrap">{{parcel.group}}</td>
                  <td class="text-nowrap" v-if="parcel.changedCity">{{parcel.changedCity}}</td>
                  <td class="text-nowrap" v-else>{{parcel.branch}}</td>
                  <!--                                <td class="text-nowrap">{{parcel.parcelCity}}</td>-->
                  <td class="text-nowrap">{{parcel.to}}</td>

                  <td>
                    <span
                        class="badge rounded-pill"
                        :class="parcel.to===parcel.changedCity ? 'bg-success':'bg-warning'"
                    >
                      {{parcel.to===parcel.changedCity?"Arrived":"On going"}}
                    </span>
                  </td>

                  <td class="text-nowrap">
                    <!-- {{parcel.date?parcel.date.toDate().toLocaleTimeString([],{ day:"numeric",month:"numeric", year:"numeric"}):''}}-->
                    {{parcel.filterDate}}

                  </td>
                </tr>
                </tbody >
              </table>
            </b-col>

          </div>
        </div>
    </div>
</template>
<script>
    import db, {timestamp} from "../../firebaseConfig";
    import Loading from "../../components/Loading";
    import InfoModal from "../../components/InfoModal";
    export default {
        name:"ParcelGroupDetails",
        components: {InfoModal, Loading},
        data(){
            return{
                retrieveCheck:false,
                loadingActive:false,
                modalMessage:"",
                modalActive:false,
                checked: false,
                currentParcel:[],
                recordLists:[],
                relatedParcelLists:[],
                sameGroupLists:[],
                sameDateLists:[],
                idLists:[],
                filterGroup:[],
                nextCity:"",
                nextCity1:"",
                groupId:"",
                groupId1:""
            }
        },
      computed:{
        todayDate(){
          const today = new Date();
          const currentDate =  today.getFullYear()+"-"+(today.getMonth() + 1).toString().padStart(2, "0")+'-'+today.getDate().toString().padStart(2,"0");
          return currentDate;
        },
        cities(){
          return this.$store.state.cities;
        },
        user(){
          return this.$store.state.user;
        },
        selection(){
          return this.$store.state.selection;
        }
      },
      async mounted(){
          if(this.user){
            this.loadingActive = true;
            await db.collection("parcelLists")
                .doc(this.$route.params.parcelID)
                .onSnapshot(snapshot=>{
                  if(snapshot.exists){
                    this.currentParcel=snapshot.data();
                    this.$bind('recordLists', db.collection('parcelLists').doc(snapshot.data().data).collection('record').orderBy("date","desc"));
                    if(snapshot.data().changedCity===snapshot.data().to && this.user.branch === snapshot.data().to){
                      this.retrieveCheck = true;
                    }
                    if(this.user.branch === 'Myawaddy' && this.selection=="date"){
                      this.$bind('relatedParcelLists' ,db.collection('parcelLists').where("filterDate","==",snapshot.data().filterDate).orderBy("date", "desc"));

                    }else if(this.user.branch === 'Myawaddy' && this.selection === "phone"){
                      this.$bind('relatedParcelLists' ,db.collection('parcelLists').where("phone","==",snapshot.data().phone).orderBy("date", "desc"));
                    }
                    else{
                      this.$bind('relatedParcelLists' ,db.collection('parcelLists').where("group","==",snapshot.data().group).orderBy("date", "desc"));
                    }
                  }
                  this.loadingActive=false;
                },error=>{
                  this.loadingActive=false;
                  console.log(error.message)
                })
          }


        },

      methods:{
        async retrieved(){
          this.loadingActive = true
          //Update the ChangeCity in parcelLists and also set the change record in record sub collection.
          const mainData = await db.collection("parcelLists").doc(this.currentParcel.data);
          await mainData.update({
            updatedAt:timestamp,
            changedCity:this.user.branch,
          }).then(()=>{
            //set the update record to the current parcel
            const updateData = db.collection("parcelLists").doc(this.currentParcel.data).collection('record').doc();
            updateData.set({
              date:timestamp,
              process:'retrieved',
              id:updateData.id,
              branch:this.user.branch,
              user:this.user.email
            }).then(()=>{
              this.loadingActive = false;
              this.modalActive = true;
              this.modalMessage = "Update Success!"
              /*setTimeout(()=>{
                this.modalActive = false;
                this.modalMessage ="";
                // this.$router.replace({name:'ParcelLists'});
              }, 1000);*/
              return;
            }).catch(error=>{
              this.loadingActive = false;
              this.modalActive = true;
              this.modalMessage = error.message;
            })
          }).catch(err=>{
            this.loadingActive = false;
            this.modalActive = true;
            this.modalMessage = err.message;
          });
        },

        //close info modal
        closeModal(){
          this.modalActive = !this.modalActive;
        },

        //show detail when click the table row
        showParcel(parcel){
          this.currentParcel=parcel;
          this.$bind('recordLists', db.collection('parcelLists').doc(parcel.data).collection('record').orderBy("date","desc"));

          // this.$router.push({name:"AllParcelGroupDetails", params:{parcelID:id}})
        },
        checkAll(event){
          if(event.target.checked){
            this.idLists = [];
            this.relatedParcelLists.forEach(parcel=>{
              this.idLists.push(parcel.id)
            })
          }else {
            this.idLists = [];
          }
        },
        check(event){
          if(event.target.checked){
            this.idLists.push(event.target.value);
          }else {
            this.idLists = this.idLists.filter((item)=>item !== event.target.value);
          }
        },

        //Update Parcel City from Other branch
        async updateCurrentParcelCity(){
          if(this.branch!==""){
            this.loadingActive = true
            //Update the ChangeCity in parcelLists and also set the changes record in record collection.
            const mainData = db.collection("parcelLists").doc(this.currentParcel.data);
            await mainData.update({
              updatedAt:timestamp,
              changedCity:this.user.branch
            }).then(()=>{
              //set the change record to the current parcel
              const updateData = db.collection("parcelLists").doc(this.currentParcel.data).collection('record').doc();
              updateData.set({
                date:timestamp,
                process:"update",
                id:updateData.id,
                branch:this.user.branch,
                user:this.user.email
              }).then(()=>{
                this.loadingActive = false;
                this.modalActive = true;
                this.modalMessage = "Update Success!"
                /*setTimeout(()=>{
                  this.modalActive = false;
                  this.modalMessage ="";
                  // this.$router.replace({name:'ParcelLists'});
                }, 1000);*/
                return;
              }).catch(error=>{
                this.loadingActive = false;
                this.modalActive = true;
                this.modalMessage = error.message;
              })
            }).catch(err=>{
              this.loadingActive = false;
              this.modalActive = true;
              this.modalMessage = err.message;
            });
          }else {
            this.loadingActive = false;
            this.modalActive = true;
            this.modalMessage = "Choose City!";
          }
        },

        updateCityByManualSelect(){
          if(this.idLists.length>0 && this.branch !== "" ){
            this.loadingActive = true;
            this.idLists.map(id=>{
              const parcelListData = db.collection("parcelLists").doc(id);
              parcelListData.update({
                updatedAt:timestamp,
                changedCity : this.user.branch
              }).then(()=> {
                const updateData = db.collection("parcelLists").doc(id).collection('record').doc();
                updateData.set({
                  date: timestamp,
                  process: "update",
                  id: updateData.id,
                  branch: this.user.branch,
                  user:this.user.email
                }).then(() => {
                  this.loadingActive = false;
                  // window.location.reload();
                }).catch(err => {
                  this.loadingActive = false;
                  this.modalActive = true;
                  this.modalMessage = err.message;
                });
              });

            });
            this.loadingActive = false;
            this.checked = false;
            this.modalActive = true;
            this.modalMessage="Update Success!";
            return;
          }else {
            this.loadingActive = false;
            this.modalActive = true;
            this.modalMessage="Select both parcel and city";
          }
        },

        updateCityForAll(){
          this.loadingActive = true;
          if(this.branch!==""){
            this.idLists.map(id=>{
              const updateChangedCity = db.collection("parcelLists").doc(id);
              updateChangedCity.update({
                updatedAt:timestamp,
                changedCity:this.user.branch
              })
                  .then(()=>{
                    const updateData = db.collection("parcelLists").doc(id).collection('record').doc();
                    updateData.set({
                      date:timestamp,
                      process:"update",
                      id:updateData.id,
                      branch:this.user.branch,
                      user:this.user.email
                    }).then(()=>{
                      this.loadingActive = false;
                      this.modalActive = false;
                      this.modalMessage = "";

                    }).catch(err=>{
                      this.loadingActive = false;
                      this.modalActive = true;
                      this.modalMessage = err.message;
                    });
                  }).catch(err=>{
                this.loadingActive = false;
                this.checked = false;
                this.modalActive = true;
                this.modalMessage = err.message;
              });
            });
            this.loadingActive = false;
            this.modalActive = true;
            this.modalMessage = "Updated All parcel!"
          }else {
            this.loadingActive = false;
            this.modalActive = true;
            this.modalMessage = "Choose City!";
          }
        },

        //Group ID Update from Myawaddy
        updateCurrentParcelGroupID(){
          if(this.groupId!==""){
            this.loadingActive = true
            console.log(this.currentParcel.data)
            //Update the ChangeCity in parcelLists and also set the changes record in record collection.
            const mainData = db.collection("parcelLists").doc(this.currentParcel.data);
            mainData.update({
              group:this.groupId,
              updatedAt:timestamp,
              changedCity:this.user.branch
            }).then(()=>{
              //set the change record to the current parcel
              const updateData = db.collection("parcelLists").doc(this.currentParcel.data).collection('record').doc();
              updateData.set({
                date:timestamp,
                process:"update",
                id:updateData.id,
                branch:this.user.branch,
                user:this.user.email
              }).then(()=>{
                this.loadingActive = false;
                this.modalActive = true;
                this.modalMessage = "Update Success!"
                return;
              }).catch(error=>{
                this.loadingActive = false;
                this.modalActive = true;
                this.modalMessage = error.message;
              })
            }).catch(err=>{
              this.loadingActive = false;
              this.modalActive = true;
              this.modalMessage = err.message;this.currentParcel.data
            });
          }else {
            this.loadingActive = false;
            this.modalActive = true;
            this.modalMessage = "Choose City!";
          }
        },

        updateGroupIdAll(){
          this.loadingActive = true;
          if(this.groupId1!==""){
            //Update the ChangeCity in parcelLists and also set the changes record in record collection.
            this.idLists.map(id=>{
              const updateChangedCityAndGroup = db.collection("parcelLists").doc(id);
              updateChangedCityAndGroup.update({
                updatedAt:timestamp,
                group:this.groupId1,
                changedCity:this.user.branch
              }).then(()=>{
                const updateData = db.collection("parcelLists").doc(id).collection('record').doc();
                updateData.set({
                  date:timestamp,
                  process:"update",
                  id:updateData.id,
                  branch:this.user.branch,
                  user:this.user.email
                }).then(()=>{
                  this.loadingActive = false;
                  this.modalActive = false;
                }).catch(err=>{
                  this.loadingActive = false;
                  this.modalActive = true;
                  this.modalMessage = err.message;
                });
              }).catch(err=>{
                this.loadingActive = false;
                this.modalActive = true;
                this.modalMessage = err.message;
              });
            })
            this.loadingActive = false;
            this.checked = false;
            this.modalActive = true;
            this.modalMessage = "Updated All parcel!"
            return;
          }else {
            this.loadingActive = false;
            this.modalActive = true;
            this.modalMessage = "Insert Group ID !";
          }
        },

        updateGroupIDByManualSelect(){
          if(this.idLists.length>0 && this.groupId1 !== "" ){
            this.loadingActive = true;
            this.idLists.map(id=>{
              const parcelListData = db.collection("parcelLists").doc(id);
              parcelListData.update({
                updatedAt:timestamp,
                group : this.groupId1,
                changedCity: this.user.branch
              }).then(()=>{
                const updateData = db.collection("parcelLists").doc(id).collection('record').doc();
                updateData.set({
                  date:timestamp,
                  process:"update",
                  id:updateData.id,
                  branch:this.user.branch,
                  user:this.user.email
                }).then(()=>{

                }).catch(err=>{
                  this.loadingActive = false;
                  this.modalActive = true;
                  this.modalMessage = err.message;
                });
              })

            });

            this.loadingActive = false;
            this.checked = false;
            this.modalActive = true;
            this.modalMessage="Update Success!";
            return;

          }else {
            this.loadingActive = false;
            this.modalActive = true;
            this.modalMessage="Select parcel and insert Group ID";
          }
        }
      }

    }
</script>

<style scoped>
    button, select,.form-floating input, h1{
        border-radius: 20px !important;
    }
    td:first-child{
        text-align: start;
    }
    td:last-child{
        text-align: start;
        white-space: nowrap;
    }
    tr{
        margin: 5px;
        border-bottom: 1px solid #faf2d9;
        border-radius: 20px;
    }
    td{
        padding: 5px;
        white-space: nowrap;
    }
    .div-card{
        border-radius: 20px;
        background-color: #F7F7F7;
        padding: 12px;
    }
    .custom-bg{
        padding: 5px;
        border-radius: 20px;
        background-color: white;
    }
</style>