<template>
  <div class="modal">
    <div class="card rounded shadow-lg">
      <div class="d-flex flex-column card-body text-center justify-content-center align-items-center">
        <i class="fa fa-info-circle fs-3 text-primary "></i>
        <p class="fs-6 fw-bolder my-3">{{this.modalMessage}}</p>
        <!--        <select class="form-select" v-model="selection" required >
          <option value="Search By" selected disabled >Search by </option>
          <option value="date" >Date</option>
          <option value="phone">Phone</option>
        </select>
        {{this.selection}}-->
        <div class="d-flex ">
          <div class="form-check mx-2">
            <input class="form-check-input" type="radio" value="date" v-model="selection" name="flexRadioDefault" id="date">
            <label class="form-check-label" for="date">
              Date
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" value="phone" v-model="selection" name="flexRadioDefault" id="phone">
            <label class="form-check-label" for="phone">
              Phone
            </label>
          </div>
        </div>
        <div class="d-flex p-1 justify-content-between mt-4">
          <button @click="choose()" class="btn btn-dark mx-2">Choose</button>
          <button id="cancel-button" @click="cancel" class="btn btn-outline-dark mx-2">No</button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      selection:null,
    }
  },
  name:"ChooseDialog",
  props:["modalMessage"],
  methods:{
    choose(){
      if(this.selection!==null){
        this.$emit("choose", this.selection)
      }
    },
    cancel(){
      this.$emit("cancel")
    }
  },
  mounted() {

  }
}
</script>

<style scoped>
.modal{
  top:0;
  z-index: 999999;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.2);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card{
  border-radius: 20px !important;
  width: 300px;
  height: 300px;
}
select{
  border-radius: 20px !important;
}
button{
  width:100px;
  border-radius: 20px !important;
}
</style>