<template>
    <div v-if="this.$store.state.user">
      <!-- Dialog Boxes-->
      <Loading v-if="loadingActive"/>
      <ConfirmDialog v-if="confirmModalActive" :modal-message="confirmModalMessage" v-on:confirm="confirm" v-on:cancel="cancel"/>
      <InfoModal v-if="modalActive" :modal-message="modalMessage" v-on:close-modal="closeModal"/>
      <ChooseDialog v-if="chooseModalActive" :modal-message="modalMessage" v-on:choose="chooseModal" v-on:cancel="cancelChoose"/>

      <div class="container" v-if="paginateParcels">
        <div class="row">
          <!-- Search Parcel Box-->
          <div class="d-flex flex-column flex-lg-row flex-md-row mt-5 pt-5 mb-2 justify-content-between align-items-center">
            <span class="fw-bold fs-3">Parcel Lists </span>
            <div class="d-flex flex-column flex-lg-row flex-md-row align-items-center">
              <div class="mx-2">
                <select class="form-select" v-model="searchAttribute"  aria-label="default select example">
                  <option value="" selected disabled >Search by </option>
                  <option v-for="(attr , index) in searchAttributes" v-bind:key="index" >
                    {{attr}}
                  </option>
                </select>
              </div>
              <div class="d-flex justify-content-between my-2">
                <button type="button" class="btn btn-date btn-outline-dark" v-if="searchAttribute==='Date'" data-bs-toggle="modal" data-bs-target="#exampleModal">
                  <i class="fa fa-calendar mx-1"></i>Choose Date
                </button>
                <div v-else class="d-flex">
                  <input type="text"  v-model="search" :disabled="disableInput" class="form-control" placeholder="Search...">
                  <button @click.prevent="searchItems" class="btn search-btn btn-warning text-white mx-1"><i class="fa fa-search "></i></button>
                </div>
              </div>
            </div>
          </div>

          <!-- Modal for Date Choose-->
          <div class="d-flex">
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel"><i class="fa fa-calendar-check"></i>Choose Date</h5>
                    <button type="button" class="btn btn-secondary" @click="searchByDate" data-bs-dismiss="modal">OK</button>
                  </div>
                  <div class="d-flex modal-body align-items-center justify-content-center">
                    <vc-calendar :attributes="attributes" @dayclick="onDayClick" />
                  </div>
                  <div class="modal-footer">
                  </div>
                </div>
              </div>
            </div>

          </div>
          <!-- Display Show All button and expost after Search View-->
          <div class="d-flex my-1 align-items-center justify-content-between" v-if="table2">
            <button class="btn btn-outline-dark " @click="showAll">Show All</button>
            <div class="d-flex">
              <button class="btn btn-success mx-2" @click="exportToExcel" v-if="table2"><i class="fa fa-file-excel mx-1"></i>Export</button>
              <!--                   <button class="btn btn-success" @click="printTable" v-if="table2">Print</button>-->
            </div>
          </div>
          <!--Table View-->
          <div class="col table-responsive mb-5">
            <!--Table for Default-->
            <table class="table table-hover table-bordered" v-if="table1">
              <thead >
              <tr>
                <th class="text-nowrap">Customer Name</th>
                <th class="text-nowrap">MS Code</th>
                <th class="text-nowrap">Order Number</th>
                <th class="text-nowrap">Phone Number</th>
                <th class="text-nowrap">Group</th>
                <th class="text-nowrap">Current City</th>
                <th class="text-nowrap">To</th>
                <th class="text-nowrap">Status</th>
                <th class="text-nowrap">Action</th>
                <th class="text-nowrap">Date</th>
              </tr>
              </thead>
              <tbody v-if="paginateParcels.length>0">
              <tr  v-for="parcel in paginateParcels" :key="parcel.parcelID" class="align-middle text-start">
                <td class="text-nowrap">{{parcel.name}}</td>
                <td>{{parcel.msCode}}</td>
                <td class="text-nowrap">{{parcel.orderNumber}}</td>
                <td class="text-nowrap">{{parcel.phone}}</td>
                <td class="text-nowrap">{{parcel.group?parcel.group:'-'}}</td>
                <td class="text-nowrap" v-if="parcel.changedCity">{{parcel.changedCity}}</td>
                <td class="text-nowrap" v-else>{{parcel.branch}}</td>
                <td class="text-nowrap">{{parcel.to}}</td>
                <td class="text-nowrap"><span class="badge rounded-pill bg-primary" :class="parcel.to===parcel.changedCity ? 'bg-success':'bg-warning'" >{{parcel.to===parcel.changedCity?"Arrived":"On going"}}</span></td>
                <td class="text-nowrap">
                  <div class="d-flex">
                    <button class="btn btn-outline-primary table-btn " @click="showParcel(parcel.data)" v-if="branchEmail!=='linemaesot@gmail.com'"><i class="fa fa-info-circle"></i></button>
                    <button class="btn btn-outline-warning table-btn mx-1" @click="updateParcel(parcel.data)" v-if="branchEmail === 'linemaesot@gmail.com' || branchEmail=== 'lineadmin@gmail.com'"><i class="fa fa-edit"></i></button>
                    <button class="btn btn-outline-danger table-btn " v-if="branchEmail ==='lineadmin@gmail.com'" @click="parcelDelete(parcel.data)"><i class="fa fa-trash"></i></button>
                  </div>

                </td>
<!--                <td class="text-nowrap">{{parcel.date.toDate().toLocaleTimeString([],{ day:"numeric",month:"numeric", year:"numeric"})}}</td>-->
                <td class="text-nowrap"> {{parcel.filterDate}}</td>
              </tr>

              <!--Table1 body for Not Found-->
              </tbody>
              <!--If parcel not exists-->
              <tbody v-else>
              <tr>
                <td colspan="10" class="text-center">No Data exists</td>
              </tr>
              </tbody>

            </table>

            <!-- Search Table-->
            <table class="table table-hover table-bordered mb-5 " id="search_table" v-if="table2" >
              <!-- Table Body for Search Parcels Lists-->
              <thead >
              <tr>
                <th>#</th>
                <th class="text-nowrap">Customer Name</th>
                <th class="text-nowrap">MS Code</th>
                <th class="text-nowrap">Order Number</th>
                <th class="text-nowrap">Phone Number</th>
                <th class="text-nowrap">Group</th>
                <th class="text-nowrap">Current City</th>
                <th class="text-nowrap">To</th>
                <th class="text-nowrap">Status</th>
                <th class="text-nowrap">Action</th>
                <th class="text-nowrap">Date</th>
              </tr>
              </thead>
              <tbody class="mb-5" v-if="searchParcelLists.length>0" >
              <tr v-for="(parcel,index) in searchParcelLists" :key="parcel.parcelID" class="align-middle text-start">
                <td>{{index+1}}</td>
                <td>{{parcel.name}}</td>
                <td>{{parcel.msCode}}</td>
                <td>{{parcel.orderNumber}}</td>
                <td>{{parcel.phone}}</td>
                <td class="text-nowrap">{{parcel.group?parcel.group:'-'}}</td>
                <td class="text-nowrap" v-if="parcel.changedCity">{{parcel.changedCity}}</td>
                <td class="text-nowrap" v-else>{{parcel.branch}}</td>
                <td class="text-nowrap">{{parcel.to}}</td>
                <td>
                              <span class="badge rounded-pill bg-primary" :class="parcel.to===parcel.changedCity ? 'bg-success':'bg-warning'" >
                                {{parcel.to===parcel.changedCity?"Arrived":"On going"}}
                              </span>
                </td>
                <td class="text-nowrap">
                  <div class="d-flex">
                    <button class="btn btn-outline-primary table-btn " @click="showParcel(parcel.data)" v-if="branchEmail!=='linemaesot@gmail.com'"><i class="fa fa-info-circle"></i></button>
                    <button class="btn btn-outline-warning table-btn mx-1" @click="updateParcel(parcel.data)" v-if="branchEmail === 'linemaesot@gmail.com' || branchEmail=== 'lineadmin@gmail.com'"><i class="fa fa-edit"></i></button>
                    <button class="btn btn-outline-danger table-btn" v-if="branchEmail ==='lineadmin@gmail.com'" @click="parcelDelete(parcel.data)"><i class="fa fa-trash"></i></button>
                  </div>
                </td>
                 <td class="text-nowrap" >{{parcel.filterDate}}</td>
<!--                <td class="text-nowrap">{{parcel.date.toDate().toLocaleTimeString('en-US',{ day:"numeric",month:"short", year:"numeric", })}}</td>-->
                <!--<td class="text-nowrap">{{parcel.date.toDate().toLocaleTimeString([],{ day:"numeric",month:"numeric", year:"numeric"})}}</td>-->

              </tr>
              </tbody>

              <!-- Table2 Body for not found-->
              <tbody v-else>
              <tr>
                <td colspan="10" class="text-center">No Data exists</td>
              </tr>
              </tbody>
            </table>
          </div>

          <!--Paginate Button for Default Table-->
          <div v-if="table1" class="mt-2 mb-5 pb-5 ">
            <div class="d-flex justify-content-center align-items-center">
              <button class="btn btn-outline-dark mx-1 search-btn mx-5" :disabled="prev_btn" @click="previous" ><i class="fa fa-angle-left fa-2x"></i></button>
              <button class="btn btn-outline-dark search-btn mx-5" :disabled="next_btn" @click="next"><i class="fa fa-angle-right fa-2x"></i></button>
            </div>
          </div>

          <!-- <div v-if="table2" class="mb-5 pb-5">
            <div  class="d-flex justify-content-center align-items-center">
              <button class="btn btn-outline-dark mx-1 rounded-circle mx-5" :disabled="search_prev_btn" @click="searchPrevious" ><i class="fa fa-angle-left fa-2x"></i></button>
              <button class="btn btn-outline-dark rounded-circle mx-5" :disabled="search_next_btn" @click="searchNext"><i class="fa fa-angle-right fa-2x"></i></button>
            </div>
          </div>-->

        </div>
      </div>

    </div>
</template>
<script>

import XLSX from 'xlsx';
    import db from '@/firebaseConfig';
    import "firebase/auth";
    import 'firebase/firestore';
    import Loading from "@/components/Loading";
    import InfoModal from "@/components/InfoModal";
    import ConfirmDialog from "@/components/ConfirmDialog";
    import ChooseDialog from "@/components/ChooseDialog";
export default {
  name:"ParcelTable",
  components: {ConfirmDialog ,ChooseDialog, InfoModal, Loading},
  data(){
    return{

      days:[],

      loadingActive : false,
      modalMessage : "",
      modalActive : false,
      confirmModalActive : false,
      confirmModalMessage : "",
      chooseModalActive:false,
      currentParcelId:null,
      deleteID:'',

      //pagination
      paginateParcels:[], //paginate parcels object arr
      limit: 10, //paginate Limit
      lastVisible: '', //paginate last
      firstVisible: '', //paginate first
      next_btn: false,
      prev_btn: true,
      search_next_btn:false,
      search_prev_btn:true,

      table1:true, //for default table view
      table2:false, //for search table view

      //Search
      filterData:[],
      search:"", //input
      searchKey:"", //query key
      date:"", //input
      translate:'en',
      query:"", //query name
      searchAttribute:"", //select input
      searchParcelLists:[], //search parcels lists object arr
      searchAttributes:[
        "Group",
        "Phone",
        "Order Number",
        "MS Code",
        "Date"
      ]
    }
  },
  computed:{

    dates() {
      return this.days.map(day => day.date);
    },
    attributes() {
      return this.dates.map(date => ({
        highlight: true,
        dates: date,
      }));
    },

    //search
    branchEmail(){
      return this.$store.state.user.email;
    },
    branch(){
      return this.$store.state. user.branch;
    },
    inputPlaceholder(){
      switch (this.searchAttribute) {
        case "Order Number":
          return "Enter orderNumber"
        case "Phone":
          return "Enter Phone"
        case "MS Code":
          return "Enter MS Code"
        case "Group":
          return "Enter group name"
        default:
          return "Choose something"

      }
    },
    disableInput(){
      if(this.searchAttribute){
        return false;
      }
      return true;
    },


  },
  async mounted() {
    //vue fire pagination
    if(this.$store.state.user){
      this.$bind('paginateParcels',db.collection('parcelLists').orderBy("filterDate","desc").orderBy("group","desc").limit(this.limit))

      // set last and first Visible
      await db.collection('parcelLists').orderBy("filterDate","desc").orderBy("group","desc").limit(this.limit).get().then(documentSnapshots => {
        if(documentSnapshots.size>0){
          this.lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1]
          this.firstVisible = documentSnapshots.docs[0]
        }
      }).then(() => {
        // peep to check if next should be on or off
        db.collection('parcelLists').orderBy("filterDate","desc").orderBy("group","desc").startAfter(this.lastVisible).limit(this.limit).get()
            .then(snap => {
              if (snap.size === 0) {
                this.next_btn = true
              }
            })
      });
    }

  },

  methods:{
    //Excel Export fnction
    exportToExcel(){
      this.searchParcelLists.forEach((doc, index)=>{
        const exportData = {
          "No":index+1,
          "Name":doc.name,
          "MS Code":doc.msCode,
          "Order Number":doc.orderNumber,
          "Group":doc.group,
          "Date":doc.filterDate
        }
        this.filterData.push(exportData);
      })
      const data = XLSX.utils.json_to_sheet(this.filterData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, data, 'data')
      XLSX.writeFile(wb,`${new Date().toLocaleDateString()}.xlsx`)
    },

    //Calendar date select function
    onDayClick(day) {
      const idx = this.days.findIndex(d => d.id === day.id);

      if (idx >= 0) {
        this.days.splice(idx, 1);
      } else {
        this.days.push({
          id: day.id,
          date: day.date.toLocaleDateString()
        });
      }
    },
    //end date

    //Click when user want to show default table view
    showAll(){
      this.table2 = false;
      this.table1 = true;
      this.searchAttribute = "";
      this.search = "";
    },

    //Choose Dialog box for Myawaddy brnch
    async showParcel(id){
      if(this.branch === "Myawaddy"){
        this.modalMessage="Choose Option to check detail"
        this.chooseModalActive = true;
        this.currentParcelId=id;
      }else {
        this.$router.push({name:"AllParcelGroupDetails", params:{parcelID:id}});
      }
    },

    //search by other field function
    searchItems() {
      switch (this.searchAttribute) {
        case "Group":
          this.query = "group";
          break;
        case "Order Number":
          this.query = "orderNumber";
          break;
        case "Phone":
          this.query = "phone";
          break;
        case "Date":
          this.query = "filterDate";
          break;
        case "MS Code":
          this.query = "msCode";
          break;
        default:
          this.query = null;
      }
      this.table2 = true;
      this.table1 = false;
      this.loadingActive = true;
      this.searchParcelLists = [];
      // this.$bind('searchParcelLists', db.collection('parcelLists').where(this.query, "==", this.search).orderBy("filterDate", "desc"));
      db.collection('parcelLists')
          .where(this.query, "==", this.search)
          .orderBy("filterDate", "desc")
      .onSnapshot(snapshot => {
        snapshot.forEach(doc=>{
          this.searchParcelLists.push(doc.data())
        })
      })
      this.loadingActive=false;
      /*if(this.search !== '') {

      }
      else{
        this.modalMessage="Choose something";
        this.modalActive=true;
        this.loadingActive = false;
      }*/

    },

    //Search by Date function
    searchByDate(){
      if(this.days.length>0){
        this.table2 = true;
        this.table1 = false;
        this.loadingActive = true;
        this.searchParcelLists = [];
        this.days.forEach(day=>{
          const date = new Date(day.date);
          const searchDate =  date.getFullYear()+"-"+(date.getMonth() + 1).toString().padStart(2, "0")+'-'+date.getDate().toString().padStart(2,"0");
          console.log(searchDate)
          db.collection('parcelLists').where("filterDate","==",searchDate).orderBy("date","desc")
              .get().then(snap=>{
            snap.forEach(doc=>{
              this.searchParcelLists.push(doc.data());
            })
          })
          this.loadingActive = false;


        })
      }else{
        this.loadingActive = false;
        this.modalMessage="Choose date";
        this.modalActive = true;
      }

    },


    //choose dialog action when Myawaddy choose date or phone
    chooseModal(selection){
      this.chooseModalActive= !this.chooseModalActive;
      this.$store.commit("setSelection", selection);
      this.$router.push({name:"AllParcelGroupDetails", params:{parcelID:this.currentParcelId}});
    },

    //cancel action for delete confirm dialog
    cancel(){
      this.modalMessage ="";
      this.confirmModalMessage = "";
      this.confirmModalActive= !this.confirmModalActive;
    },

    //cancel action for choose dialog
    cancelChoose(){
      this.chooseModalActive = !this.chooseModalActive;

    },

    //close the modal dialog
    closeModal(){
      this.modalActive = !this.modalActive;
      this.modalMessage = "";
    },

    //bind data to const id when user click the delete button
    parcelDelete(id){
      this.deleteID = id;
      this.confirmModalActive = true;
      this.confirmModalMessage = "Are you sure to delete!"
    },
    //confirm to delete
    async confirm(){
      const id = this.deleteID;
      await db.collection("parcelLists")
          .doc(id)
          .collection('record')
          .get()
          .then(querySnapshot =>{
            querySnapshot.forEach(doc=>{
              doc.ref.delete();
            });
            this.modalActive = true;
            this.modalMessage="Deleted!";
            this.confirmModalActive = false;
          }).catch(err=>{
            this.confirmModalActive = false;
            this.modalMessage=err.message;
            this.modalActive = true;
          })
      const getParcel = await db.collection("parcelLists").doc(id);
      await getParcel.delete();
      /*this.paginateParcels = this.paginateParcels.filter((parcel)=>{
          return parcel.data !== id;
      })*/
    },

    //Confirm dialog to delete
    deleteParcel(){
      this.confirmModalActive = true;
      this.confirmModalMessage = "Are you sure to delete!"
    },

    //route to update page
    updateParcel(id){
      this.$router.push({name:"UpdateParcel", params:{parcelID:id}});
    },

    //paginate next
    next(){
      if(!this.next_btn){
        // bind data with paginateParcels
        this.$bind('paginateParcels', db.collection('parcelLists').orderBy("filterDate","desc").orderBy("group","desc").startAfter(this.lastVisible).limit(this.limit))
        // set last and first visible items
        // console.log("next"+this.lastVisible.data().msCode)
        db.collection('parcelLists').orderBy("filterDate","desc").orderBy("group","desc").startAfter(this.lastVisible).limit(this.limit).get().then(documentSnapshots => {
          this.lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1]
          this.firstVisible = documentSnapshots.docs[0]
        }).then(() => {
          // Peep  on the next  next query to see if it gives zero
          db.collection('parcelLists').orderBy("filterDate","desc").orderBy("group","desc").startAfter(this.lastVisible).limit(this.limit).get()
              .then(snap => {
                if (snap.size === 0) {
                  //disable button if the next peeped result gets zero
                  this.next_btn = true
                  // enable previous button
                  this.prev_btn = false
                } else {
                  // enable next button if peeped result is not zero
                  this.next_btn = false
                  // enable previous button
                  this.prev_btn = false
                }
              })
        })
      }
    },

    //paginate previous
    previous () {
      // Ensure previous is not zero
      db.collection('parcelLists').orderBy("filterDate","desc").orderBy("group","desc").endBefore(this.firstVisible).limitToLast(this.limit).get().then(snap => { return snap.size })
          .then(size => {
            //confirm is not zero here
            if (size !== 0) {
              //bind the previous to countries
              this.$bind('paginateParcels', db.collection('parcelLists').orderBy("filterDate","desc").orderBy("group","desc").endBefore(this.firstVisible).limitToLast(this.limit))
              // Set last and first visible
              db.collection('parcelLists').orderBy("filterDate","desc").orderBy("group","desc").endBefore(this.firstVisible).limitToLast(this.limit).get().then(documentSnapshots => {
                this.lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1]
                this.firstVisible = documentSnapshots.docs[0]
              }).then(() => {
                // peep the next previous query
                db.collection('parcelLists').orderBy("date","desc").orderBy("group","desc").endBefore(this.firstVisible).limitToLast(this.limit).get()
                    .then(snap => {
                      if (snap.size === 0) {
                        //if next peeped previous button gets 0 disable
                        this.prev_btn = true
                        this.next_btn = false
                      } else {
                        //if next peeped result is does not get 0 enable buttons
                        this.prev_btn = false
                        this.next_btn = false
                      }
                    })
              })
            }
          })
    },

    /*searchNext(){
      if(!this.search_next_btn){
        this.$bind('searchParcelLists', db.collection('parcelLists').where(this.query, "==", this.searchKey).orderBy("date", "desc").startAfter(this.lastVisible).limit(this.limit))

        // bind data with paginateParcels
        // set last and first visible items
        // console.log("next"+this.lastVisible.data().msCode)
        db.collection('parcelLists').where(this.query, "==", this.searchKey).orderBy("date", "desc").startAfter(this.lastVisible).limit(this.limit).get().then(documentSnapshots => {
          this.lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1]
          this.firstVisible = documentSnapshots.docs[0]
        }).then(() => {
          // Peep  on the next  next query to see if it gives zero
          db.collection('parcelLists').where(this.query, "==", this.searchKey).orderBy("date", "desc").startAfter(this.lastVisible).limit(this.limit).get()
              .then(snap => {
                if (snap.size === 0) {
                  //disable button if the next peeped result gets zero
                  this.search_next_btn = true
                  // enable previous button
                  this.search_prev_btn = false
                } else {
                  // enable next button if peeped result is not zero
                  this.search_next_btn = false
                  // enable previous button
                  this.search_prev_btn = false
                }
              })
        })
      }
    },

    searchPrevious () {
      // Ensure previous is not zero
      db.collection('parcelLists').where(this.query, "==", this.searchKey).orderBy("date", "desc").endBefore(this.firstVisible).limitToLast(this.limit).get().then(snap => { return snap.size })
          .then(size => {
            //confirm is not zero here
            if (size !== 0) {
              //bind the previous to countries
              this.$bind('searchParcelLists', db.collection('parcelLists').where(this.query, "==", this.searchKey).orderBy("date", "desc").endBefore(this.firstVisible).limitToLast(this.limit))
              // Set last and first visible
              db.collection('parcelLists').where(this.query, "==", this.searchKey).orderBy("date", "desc").endBefore(this.firstVisible).limitToLast(this.limit).get().then(documentSnapshots => {
                this.lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1]
                this.firstVisible = documentSnapshots.docs[0]
              }).then(() => {
                // peep the next previous query
                db.collection('parcelLists').where(this.query, "==", this.searchKey).orderBy("date", "desc").endBefore(this.firstVisible).limitToLast(this.limit).get()
                    .then(snap => {
                      if (snap.size === 0) {
                        //if next peeped previous button gets 0 disable
                        this.search_prev_btn = true
                        this.search_next_btn = false
                      } else {
                        //if next peeped result is does not get 0 enable buttons
                        this.search_prev_btn = false
                        this.search_next_btn = false
                      }
                    })
              })
            }
          })
    }*/
  }


}
</script>

<style scoped>

input, select{
  border-radius: 20px !important;
}
.search-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  width:40px;
  height: 40px;
  border-radius: 50%;
}
.table-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.modal{
  border-radius: 20px !important;
}
.btn-date{
  border-radius: 20px !important;
}

input{
  width: 200px;
}

</style>